import React, { Component } from 'react';
import { Button, Card, Col, Form } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class NotificheReparti extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            newEmail: '',
            newEmails: {},
        };
    }

    toggleEdit = () => {
        if (this.state.isEditing) {
            this.props.sendData();
        }
        this.setState(prevState => ({
            isEditing: !prevState.isEditing
        }));
    }
    cancelEdit = () => {

        this.props.resetData(); // Chiama resetData del componente genitore per ripristinare i dati
        this.setState({ isEditing: false, newEmails: {} });// Imposta isEditing su false per uscire dalla modalità di modifica
    }

    handleChange = (event, reparto, tipo, chiave) => {
        const value = event.target.checked;
        let newData = { ...this.props.dati };
        newData[reparto][tipo][chiave]['ATTIVO'] = value;
        this.props.updateData(newData);
    }

    /*
    handleEmailChange = (event) => {
        this.setState({ newEmail: event.target.value });
    }*/
    handleEmailChange = (event, reparto, tipo, chiave) => {
        const newEmailValue = event.target.value;
        this.setState(prevState => ({
            newEmails: {
                ...prevState.newEmails,
                [`${reparto}-${tipo}-${chiave}`]: newEmailValue
            }
        }));
    }

    addEmail = (reparto, tipo, chiave) => {
        const emailKey = `${reparto}-${tipo}-${chiave}`;
        const newEmail = this.state.newEmails[emailKey];
        if (newEmail) {
            let newData = { ...this.props.dati };
            newData[reparto][tipo][chiave]['MAIL'].push(newEmail);
            this.setState(prevState => ({
                newEmails: {
                    ...prevState.newEmails,
                    [emailKey]: '', // Resetta il campo di input dopo l'aggiunta
                }
            }));
            this.props.updateData(newData);
        }
    }

    removeEmail = (index, reparto, tipo, chiave) => {
        let newData = { ...this.props.dati };
        newData[reparto][tipo][chiave]['MAIL'].splice(index, 1);
        this.props.updateData(newData);
    }

    renderEmails = (emails, reparto, tipo, chiave) => {
        return emails.map((email, index) => (
            <div key={`${reparto}-${tipo}-${chiave}-email-${index}`} style={{ marginBottom: '2%' }}>
                {email}
                {this.state.isEditing && (
                    <Button className='deleteButton' size="sm" variant="danger" onClick={() => this.removeEmail(index, reparto, tipo, chiave)}>Rimuovi</Button>
                )}
            </div>
        ));
    }


    /*
        addEmail = (reparto, tipo, chiave) => {
            const { newEmail } = this.state;
            if (newEmail) {
                let newData = { ...this.props.dati };
                newData[reparto][tipo][chiave]['MAIL'].push(newEmail);
                this.setState({ newEmail: '' });
                this.props.updateData(newData);
            }
        }
    
        removeEmail = (index, reparto, tipo, chiave) => {
            let newData = { ...this.props.dati };
            newData[reparto][tipo][chiave]['MAIL'].splice(index, 1);
            this.props.updateData(newData);
        }
    
        renderEmails = (emails, reparto, tipo, chiave) => {
            return emails.map((email, index) => (
                <div key={`${reparto}-${tipo}-${chiave}-email-${index}`} style={{ marginBottom: '2%' }}>
                    {email}
                    {this.state.isEditing && (
                        <Button className='deleteButton' size="sm" variant="danger" onClick={() => this.removeEmail(index, reparto, tipo, chiave)}>Rimuovi</Button>
                    )}
                </div>
            ));
        }
    */



    renderSwitches = (reparto, tipo) => {
        const datiReparto = this.props.dati[reparto][tipo];
        return Object.keys(datiReparto).map((chiave) => (
            <div key={`${reparto}-${tipo}-${chiave}`}>
                {this.state.isEditing ? (
                    <>
                        <Form.Check
                            type="switch"
                            id={`${reparto}-${tipo}-${chiave}-switch`}
                            label={`${chiave} (${datiReparto[chiave]['ATTIVO'] ? 'Attivo' : 'NON Attivo'})`}
                            checked={datiReparto[chiave]['ATTIVO']}
                            onChange={(e) => this.handleChange(e, reparto, tipo, chiave)}
                        />
                        {datiReparto[chiave]['MAIL'] && (
                            <>
                                {this.renderEmails(datiReparto[chiave]['MAIL'], reparto, tipo, chiave)}
                                <Form.Control
                                    className='formMail'
                                    type="email"
                                    placeholder="Nuova email"
                                    value={this.state.newEmails[`${reparto}-${tipo}-${chiave}`] || ''}
                                    onChange={(e) => this.handleEmailChange(e, reparto, tipo, chiave)}
                                />
                                <Button className='addButton' size='sm' onClick={() => this.addEmail(reparto, tipo, chiave)}>Aggiungi Email</Button>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <div >
                            <b className='infoParametro'>{`${chiave}: `}</b>
                            {`${datiReparto[chiave]['ATTIVO'] ? 'Attivo' : 'NON Attivo'}`}
                        </div>
                        <div style={{ marginBottom: '4%' }}>
                            <small className='infoParametro'>Destinatari: </small>
                            {datiReparto[chiave]['MAIL'] && this.renderEmails(datiReparto[chiave]['MAIL'], reparto, tipo, chiave)}
                        </div>
                    </>
                )}
            </div>
        ));
    }
    render() {
        const { isEditing } = this.state;
        const { reparto } = this.props; // Passa 'TaglioPelle' o 'TaglioAusiliari' come prop

        return (
            <Card className='cardModifiche'>
                <Card.Header>
                    <div className='titleCard'>
                        Notifiche reparto {reparto}
                        {/*<Button className='modButton' onClick={this.toggleEdit} style={{ float: 'right' }}>
                            {isEditing ? 'Fine Modifica' : 'Modifica'}
        </Button>*/}
                        {isEditing ?
                            (<>
                                <Button className='confirmButton' onClick={this.toggleEdit} style={{ float: 'right' }}>
                                    Conferma Modifica
                                </Button>
                                <Button className='cancelButton' variant='warning' onClick={this.cancelEdit}>
                                    Annulla Modifiche
                                </Button>
                            </>
                            )
                            : (<Button className='modButton' onClick={this.toggleEdit} style={{ float: 'right' }}>
                                Modifica
                            </Button>)
                        }
                    </div>
                </Card.Header>
                <Card.Body style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {['CAPO REPARTO', 'MANUTENZIONE', 'PREPARAZIONE'].map((tipo) => (
                        <Col key={tipo}>
                            <div className='subTitleCard'>{tipo}</div>
                            {this.renderSwitches(reparto, tipo)}
                        </Col>
                    ))}
                </Card.Body>
            </Card>
        );
    }
}




export default withTranslation()(NotificheReparti)