import React, { Component } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { GetUserAttributes, UpdateUserAttributes } from '../../components/Functions/GetUsersInfo';
import Loading from '../Loading';
import { Card, Table } from 'react-bootstrap';
import ConfigSys from './ConfigMod/ConfigSys';
import './UserDetail.css'
import UserMod from './ConfigMod/UserMod';
import { withTranslation } from 'react-i18next';

class UserDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            user: null,
            configUser: null,
            configSys: null
        };
    }

    async componentDidMount() {
        const { username } = this.props.params;
        const gg = await GetUserAttributes(username);
        console.log('gg', gg);
        var configUser = gg.Attributes.find(attr => attr.Name === 'custom:configUser')?.Value;
        configUser = JSON.parse(configUser);

        var configSys = gg.Attributes.find(attr => attr.Name === 'custom:configSys')?.Value;
        console.log('sys', configSys)
        configSys = JSON.parse(configSys);

        this.setState({
            user: gg,
            configUser,
            configSys
        })
    }

    sendData = async (user) => {
        await UpdateUserAttributes(user);


    }
    render() {
        //const { username } = this.props.params;

        const { user, configUser, configSys } = this.state;



        if (user === null) {
            return <Loading />
        }

        return (
            <div>
                <UserMod user={user} configSys={configSys} configUser={configUser} sendData={this.sendData} navigate={this.props.navigate} />
                <ConfigSys configSys={configSys} user={user} sendData={this.sendData} navigate={this.props.navigate}/>
            </div >
        );
    }
}

export default withTranslation()(UserDetail);





export function UserDetailWrapper() {
    const params = useParams(); // Ottieni i parametri dell'URL, ad es. { username: "nome_utente" }
    let navigate = useNavigate();
    return <UserDetail params={params} navigate={navigate} />;
}


