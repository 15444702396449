import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Card, Col, Form, Row, SplitButton } from 'react-bootstrap';

export class NotificheCapoStabilimento extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            newEmail: ''
        };
    }

    toggleEdit = () => {
        if (this.state.isEditing) {
            this.props.sendData();
        }
        this.setState(prevState => ({
            isEditing: !prevState.isEditing
        }));
    }
    cancelEdit = () => {

        this.props.resetData(); // Chiama resetData del componente genitore per ripristinare i dati
        this.setState({ isEditing: false });// Imposta isEditing su false per uscire dalla modalità di modifica
    }


    handleEmailChange = (e) => {
        this.setState({ newEmail: e.target.value });
    }
    addEmail = () => {
        const { newEmail } = this.state;
        if (newEmail) {
            // Costruisci il nuovo JSON aggiornato
            let newData = { ...this.props.dati };
            newData['CAPO STABILIMENTO']['MAIL'].push(newEmail);

            // Aggiorna lo stato locale
            this.setState({
                newEmail: '', // Reset input field
                //emails: newData['CAPO STABILIMENTO']['MAIL'] // Aggiorna l'elenco delle email nel componente locale
            });
            let send = false;
            // Chiama la funzione passata dal genitore per aggiornare l'intero JSON
            this.props.updateData(newData);
        }
    }

    removeEmail = (index) => {
        // Crea una copia della lista attuale di email
        let updatedEmails = [...this.props.dati['CAPO STABILIMENTO']['MAIL']];

        // Rimuovi l'email all'indice specificato
        updatedEmails.splice(index, 1);

        // Crea una copia del JSON dati per aggiornare
        let newData = { ...this.props.dati };

        // Aggiorna la lista di email nel JSON copiato
        newData['CAPO STABILIMENTO']['MAIL'] = updatedEmails;

        // Aggiorna lo stato locale se necessario
        // this.setState({ ... });

        // Chiama la funzione passata dal genitore per aggiornare l'intero JSON
        this.props.updateData(newData);
    }



    handleChange = (event) => {
        const { dataset, checked } = event.target;
        const { section, subsection, key } = dataset;

        // Costruisci il nuovo stato del JSON usando le informazioni dal dataset
        let newData = { ...this.props.dati };

        if (newData[section] && newData[section][subsection]) {
            newData[section][subsection][key] = checked;
            this.props.updateData(newData); // Funzione per aggiornare i dati nel componente genitore
        }
    }


    render() {
        const { isEditing, newEmail } = this.state;
        var dati = this.props.dati;
        console.log("dati", dati);
        return (
            <Card className='cardModifiche'>
                <Card.Header >
                    <div className='titleCard' >Notifiche Mail Capo Stabilimento
                        {/*<Button className='modButton' onClick={this.toggleEdit} style={{ float: 'right' }}>
                            {isEditing ? 'Conferma Modifica' : 'Modifica'}
        </Button>*/}
                        {isEditing ?
                            (<>
                                <Button className='confirmButton' onClick={this.toggleEdit} style={{ float: 'right' }}>
                                    Conferma Modifica
                                </Button>
                                <Button className='cancelButton' variant='warning' onClick={this.cancelEdit}>
                                    Annulla Modifiche
                                </Button>
                            </>
                            )
                            : (<Button className='modButton' onClick={this.toggleEdit} style={{ float: 'right' }}>
                                Modifica
                            </Button>)
                        }


                    </div>
                </Card.Header>
                <Card.Body style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Col >
                        <div className='subTitleCard'>
                            Indirizzi mail di notifica:<br />
                        </div>
                        {dati['CAPO STABILIMENTO']['MAIL'].map((mail, index) => (
                            <div
                                key={index}>{mail}
                                {isEditing &&
                                    (<Button className='deleteButton' variant="danger" size="sm" onClick={() => this.removeEmail(index)}>Rimuovi</Button>)
                                }
                            </div>

                        ))}
                        {isEditing && (
                            <Form.Group>
                                <Form.Control className='formMail'
                                    type="email"
                                    value={newEmail}
                                    onChange={this.handleEmailChange}
                                    placeholder="Nuova email"
                                />
                                <Button className='addButton' size='sm' onClick={this.addEmail}>Aggiungi</Button>
                            </Form.Group>
                        )}
                    </Col>
                    <Col >
                        <div className='subTitleCard'>
                            Notifiche richiesta Capo Reparto:<br />
                        </div>
                        <b className='infoParametro'>Urgente: </b>
                        {isEditing ? (
                            <Form.Check
                                className='switchButton'
                                type="switch"
                                id="capo-reparto-urgente-switch"
                                name="capoRepartoUrgenteSwitch" // Nome generico per il controllo
                                checked={dati['CAPO STABILIMENTO']['CAPO REPARTO']['URGENTE']}
                                label={(dati['CAPO STABILIMENTO']['CAPO REPARTO']['URGENTE'] ? 'Attivo' : 'NON Attivo')}
                                onChange={this.handleChange}
                                data-section="CAPO STABILIMENTO"
                                data-subsection="CAPO REPARTO"
                                data-key="URGENTE"
                            />


                        ) : (dati['CAPO STABILIMENTO']['CAPO REPARTO']['URGENTE'] ? 'Attivo' : 'NON Attivo')} <br />
                        <b className='infoParametro'>Non Urgente: </b>
                        {isEditing ? (
                            <Form.Check
                                className='switchButton'
                                type="switch"
                                id="capo-reparto-NON-urgente-switch"
                                name="capoRepartoNONUrgenteSwitch"
                                checked={dati['CAPO STABILIMENTO']['CAPO REPARTO']['NON URGENTE']}
                                label={(dati['CAPO STABILIMENTO']['CAPO REPARTO']['NON URGENTE'] ? 'Attivo' : 'NON Attivo')}
                                onChange={this.handleChange}
                                data-section="CAPO STABILIMENTO"
                                data-subsection="CAPO REPARTO"
                                data-key="NON URGENTE"
                            />) : (dati['CAPO STABILIMENTO']['CAPO REPARTO']['NON URGENTE'] ? 'Attivo' : 'NON Attivo')}<br />
                    </Col>
                    <Col >
                        <div className='subTitleCard'>
                            Notifiche richiesta Preparazione:<br />
                        </div>
                        <b className='infoParametro'>Urgente: </b>
                        {isEditing ? (
                            <Form.Check
                                className='switchButton'
                                type="switch"
                                id="preparazione-urgente-switch"
                                checked={dati['CAPO STABILIMENTO']['PREPARAZIONE']['URGENTE']}
                                label={(dati['CAPO STABILIMENTO']['PREPARAZIONE']['URGENTE'] ? 'Attivo' : 'NON Attivo')}
                                onChange={this.handleChange}
                                data-section="CAPO STABILIMENTO"
                                data-subsection="PREPARAZIONE"
                                data-key="URGENTE"
                            />
                        ) : (dati['CAPO STABILIMENTO']['PREPARAZIONE']['URGENTE'] ? 'Attivo' : 'NON Attivo')}<br />
                        <b className='infoParametro'>Non Urgente: </b>
                        {isEditing ? (
                            <Form.Check
                                className='switchButton'
                                type="switch"
                                id="preparazione-non-urgente-switch"
                                checked={dati['CAPO STABILIMENTO']['PREPARAZIONE']['NON URGENTE']}
                                label={(dati['CAPO STABILIMENTO']['PREPARAZIONE']['NON URGENTE'] ? 'Attivo' : 'NON Attivo')}
                                onChange={this.handleChange}
                                data-section="CAPO STABILIMENTO"
                                data-subsection="PREPARAZIONE"
                                data-key="NON URGENTE"
                            />
                        ) : (dati['CAPO STABILIMENTO']['PREPARAZIONE']['NON URGENTE'] ? 'Attivo' : 'NON Attivo')}<br />
                    </Col>
                    <Col >
                        <div className='subTitleCard'>
                            Notifiche richiesta Manutenzione:<br />
                        </div>
                        <b className='infoParametro'>Urgente: </b>
                        {isEditing ? (
                            <Form.Check
                                className='switchButton'
                                type="switch"
                                id="manutenzione-urgente-switch"
                                checked={dati['CAPO STABILIMENTO']['MANUTENZIONE']['URGENTE']}
                                label={(dati['CAPO STABILIMENTO']['MANUTENZIONE']['URGENTE'] ? 'Attivo' : 'NON Attivo')}
                                onChange={this.handleChange}
                                data-section="CAPO STABILIMENTO"
                                data-subsection="MANUTENZIONE"
                                data-key="URGENTE"
                            />
                        ) : (dati['CAPO STABILIMENTO']['MANUTENZIONE']['URGENTE'] ? 'Attivo' : 'NON Attivo')}<br />
                        <b className='infoParametro'>Non Urgente: </b>
                        {isEditing ? (
                            <Form.Check
                                className='switchButton'
                                type="switch"
                                id="manutenzione-non-urgente-switch"
                                checked={dati['CAPO STABILIMENTO']['MANUTENZIONE']['NON URGENTE']}
                                label={(dati['CAPO STABILIMENTO']['MANUTENZIONE']['NON URGENTE'] ? 'Attivo' : 'NON Attivo')}
                                onChange={this.handleChange}
                                data-section="CAPO STABILIMENTO"
                                data-subsection="MANUTENZIONE"
                                data-key="NON URGENTE"
                            />
                        ) : (dati['CAPO STABILIMENTO']['MANUTENZIONE']['NON URGENTE'] ? 'Attivo' : 'NON Attivo')}<br />
                    </Col>

                </Card.Body>
            </Card>
        )
    }
}

export default withTranslation()(NotificheCapoStabilimento)