import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { getS3File, uploadJsonToS3 } from '../../../components/Config';

import Loading from '../../Loading';
import NotificheCapoStabilimento from './CapoStabilimento/NotificheCapoStabilimento';
import configSettings from '../../../configParams.json';
import { saveJsontos3 } from '../../../components/Functions/SaveJsonTos3';
import './Notifications.css';
import NotificheReparti from './Reparti/NotificheReparti';
const fileName = "FMM_mail_config.json";
const path = "protected/Config/";

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dati: null,
            datiIniziali: null
        };
    }
    async componentDidMount() {
        let dd = await getS3File(fileName);
        dd = JSON.parse(dd);
        console.log('getS3File',dd);

        this.setState({
            dati: { ...dd },
            datiIniziali: JSON.parse(JSON.stringify(dd))
        })
    }
    componentWillUnmount() {
        this.setState({
            dati: null
        })
    }

    updateData = newData => {
        this.setState({ dati: { ...newData } });
    }
    resetData = () => {
        /*this.setState((prevState) => ({
            dati: {...prevState.datiIniziali} // Ripristina i dati iniziali
        }));*/
        const datiNew = this.state.datiIniziali;
        console.log("qui", datiNew);
        this.setState({
            dati: JSON.parse(JSON.stringify(datiNew))
        })
    }


    sendData = async () => {
        const sendData = await saveJsontos3(fileName, this.state.dati, path);
        console.log(sendData);
        const datiNew = this.state.dati;
        let dd = await getS3File(fileName);
        console.log('dd',dd);
        this.setState({
            datiIniziali: JSON.parse(JSON.stringify(datiNew))
        })
    }


    render() {
        const dati = this.state.dati;

        if (dati == null) {
            return (
                <Loading></Loading>
            )
        }

        return (
            <div >
                <NotificheCapoStabilimento dati={dati} updateData={this.updateData} sendData={this.sendData} resetData={this.resetData} />
                <NotificheReparti reparto="TaglioPelle" dati={dati} updateData={this.updateData} sendData={this.sendData} resetData={this.resetData} />
                <NotificheReparti reparto="TaglioAusiliari" dati={dati} updateData={this.updateData} sendData={this.sendData} resetData={this.resetData} />

            </div>


        );
    }



}
export default withTranslation()(Notifications);