import React, { Component } from 'react';
import { Button, Form, Container, Row, Col, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CreateUser, SetPermanentPassword } from '../../../components/Functions/GetUsersInfo';
import { InfoCircle } from 'react-bootstrap-icons';
import { Navigate, redirect, useNavigate, useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class UserForm extends Component {
    state = {
        username: '',
        password: '',
        name: '',
        family_name: '',
        email: '',
        // ConfigUser
        client: '',
        clientCode: '',
        lang: 'IT',
        favApp: '',
        goToFavApp: true,
        // ConfigSys
        factorySet: '',
        policyMqtt: 0,
        levelAuth: 2,
        POSTAZIONE: ['ALL'],
        REPARTO: '',
        isPasswordValid: true,

        showPassword: false,
    };
    clients = [
        { client: '-', clientCode: '-' },
        { client: 'BottegaVeneta', clientCode: 'BV' }
    ];

    handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        if (name === "password") {
            console.log(value);
            this.setState({ passwordError: '' });
        }
        if (type === 'number') {
            this.setState({ [name]: parseInt(value) || 0 });
        } else if (type === 'checkbox') {
            this.setState({ [name]: checked });
        } else {
            this.setState({ [name]: value });
        }
    };
    validatePassword = () => {
        const { password } = this.state;
        const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\^$*.\\[\]{}()?\-"!@#%&/\\,><':;|_~`+=]).{8,}$/
            ;
        console.log(password);
        if (!regex.test(password)) {
            this.setState({
                passwordError: 'La password non soddisfa i requisiti minimi.',
                isPasswordValid: false,
            });
            return false;
        } else {
            this.setState({ isPasswordValid: true });
            return true;
        }
    };


    renderPasswordRequirementsTooltip = (props) => (
        <Tooltip id="password-requirements" {...props}>
            Requisiti della password:
            <ul>
                <li>Lunghezza minima di 8 caratteri</li>
                <li>Contiene almeno 1 numero</li>
                <li>Contiene almeno 1 carattere minuscolo</li>
                <li>Contiene almeno 1 carattere maiuscolo</li>
                <li>Contiene almeno 1 carattere speciale (^ $ * . [ ] { } ( ) ? - " ! @ # % &amp; / \ , &gt; &lt; ' : ; | _ ~ ` + =)</li>
            </ul>
        </Tooltip>
    );
    toggleShowPassword = () => {
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    };
    handlePostazioneChange = (index, value) => {
        this.setState(prevState => ({
            POSTAZIONE: prevState.POSTAZIONE.map((postazione, i) => i === index ? value : postazione)
        }));
    };

    handleAddPostazione = () => {
        this.setState(prevState => ({
            POSTAZIONE: [...prevState.POSTAZIONE, '']
        }));
    };

    handleRemovePostazione = (index) => {
        this.setState(prevState => ({
            POSTAZIONE: prevState.POSTAZIONE.filter((_, i) => i !== index)
        }));
    };


    handleClientChange = (event) => {
        const selectedClient = this.clients.find(client => client.client === event.target.value || client.clientCode === event.target.value);
        this.setState({
            client: selectedClient.client,
            clientCode: selectedClient.clientCode,
        });
    };
    renderTooltip = (message) => (
        <Tooltip>
            {message}
        </Tooltip>
    );

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({ passwordError: '' });

        // Validazione della password
        if (!this.validatePassword()) {
            return; // Ferma qui se la password non è valida
        }
        // Costruisci l'oggetto userData con i dati del form
        const userData = {
            username: this.state.username,
            password: this.state.password,
            name: this.state.name,
            family_name: this.state.family_name,
            email: this.state.email,
            // Aggiungi altri campi qui
            configUser: {
                client: this.state.client,
                clientCode: this.state.clientCode,
                userSettings: {
                    lang: this.state.lang,
                    favApp: this.state.favApp,
                    goToFavApp: this.state.goToFavApp
                }
            },
            configSys: {
                FMM: {
                    settings: {
                        factorySet: this.state.factorySet
                    },
                    factories: [
                        {
                            [this.state.factorySet]: {
                                policyMqtt: this.state.policyMqtt,  // assicurati che questi siano numeri
                                levelAuth: this.state.levelAuth,    // assicurati che questi siano numeri
                                POSTAZIONE: this.state.POSTAZIONE,
                                REPARTO: this.state.REPARTO
                            }
                        }
                    ]
                }
            }

        };
        console.log(userData);

        try {
            const userCreationResponse = await CreateUser(userData);
            console.log('Risposta creazione utente:', userCreationResponse);

            this.props.navigate(-1);
            // Qui puoi reindirizzare l'utente o mostrare un messaggio di successo
        } catch (error) {
            console.error('Errore nella creazione dell\'utente');

        }
    }

    getBack() {
        this.props.navigate(-1);
    }

    render() {
        const opzioniReparto = ["-", "TaglioPelle", "TaglioAusiliari"];
        const opzioniLanguage = ['IT', 'EN', 'US'];
        const opzioniFactory = ['-', 'Malo'];
        const opzioniFavouriteApp = ['-', 'FMM'];
        return (
            <Container >
                <Form onSubmit={this.handleSubmit}>
                    <Card className='cardModifiche'>
                        <Card.Header>Configurazione nuovo utente</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Form.Group controlId="username">
                                        <Form.Label>Username</Form.Label>
                                        <Form.Control
                                            className='formEditUserMod'
                                            type="text"
                                            name="username"
                                            value={this.state.username}
                                            onChange={this.handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="password">
                                        <Form.Label>Password</Form.Label>
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={this.renderPasswordRequirementsTooltip}
                                        >
                                            <Form.Control
                                                type={this.state.showPassword ? "text" : "password"}

                                                name="password"
                                                value={this.state.password}
                                                onChange={this.handleInputChange}
                                                isInvalid={!!this.state.passwordError}
                                            />
                                        </OverlayTrigger>

                                        <Form.Control.Feedback type="invalid">
                                            {this.state.passwordError}
                                        </Form.Control.Feedback>
                                        <Form.Check
                                            className='switchButton'
                                            type="switch"
                                            id="show-password-switch"
                                            label="Mostra Password"
                                            checked={this.state.showPassword}
                                            onChange={this.toggleShowPassword}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            className='formEditUserMod'
                                            type="text"
                                            name="name"
                                            value={this.state.name}
                                            onChange={this.handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="family_name">
                                        <Form.Label>Family Name</Form.Label>
                                        <Form.Control
                                            className='formEditUserMod'
                                            type="text"
                                            name="family_name"
                                            value={this.state.family_name}
                                            onChange={this.handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            className='formEditUserMod'
                                            type="email"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="client">
                                        <Form.Label>Client</Form.Label>
                                        <Form.Select
                                            className='formEditUserMod'
                                            name="client"
                                            value={this.state.client}
                                            onChange={this.handleClientChange}>
                                            {this.clients.map(({ client }) => (
                                                <option key={client} value={client}>{client}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="clientCode">
                                        <Form.Label>Client Code</Form.Label>
                                        <Form.Select
                                            className='formEditUserMod'
                                            name="clientCode"
                                            value={this.state.clientCode}
                                            onChange={this.handleClientChange}>
                                            {this.clients.map(({ clientCode }) => (
                                                <option key={clientCode} value={clientCode}>{clientCode}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="lang">
                                        <Form.Label>language</Form.Label>
                                        <Form.Select
                                            className='formEditUserMod'
                                            size="sm"

                                            type="text"
                                            name="lang"
                                            value={this.state.lang}
                                            onChange={this.handleInputChange}
                                        >
                                            {opzioniLanguage.map((opzione) => (
                                                <option key={opzione} value={opzione}>{opzione}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="favApp">
                                        <Form.Label>Favourite App</Form.Label>
                                        <Form.Select
                                            className='formEditUserMod'
                                            type="text"
                                            name="favApp"
                                            value={this.state.favApp}
                                            onChange={this.handleInputChange}
                                        >
                                            {opzioniFavouriteApp.map((opzione) => (
                                                <option key={opzione} value={opzione}>{opzione}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="factorySet">
                                        <Form.Label>factory Set</Form.Label>
                                        <Form.Select
                                            className='formEditUserMod'
                                            size="sm"
                                            type="text"
                                            name="factorySet"
                                            value={this.state.factorySet}
                                            onChange={this.handleInputChange}
                                        >
                                            {opzioniFactory.map((opzione) => (
                                                <option key={opzione} value={opzione}>{opzione}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    {opzioniFactory.slice(1).map((factory, index) => (
                        <Card className='cardModifiche'>
                            <Card.Header>Configurazione per {factory}</Card.Header>
                            <Card.Body>
                                <Row>

                                    <Col>
                                        <Form.Group controlId="policyMqtt">
                                            <Form.Label>policy mqtt
                                                <OverlayTrigger
                                                    placement="right"
                                                    overlay={this.renderTooltip('0 se è un nuovo utente')}
                                                >
                                                    <InfoCircle className="ml-2" style={{ cursor: 'pointer', marginLeft: '5px' }} />
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <Form.Control
                                                className='formEditUserMod'
                                                type="number"
                                                name="policyMqtt"
                                                value={this.state.policyMqtt}
                                                onChange={this.handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="levelAuth">
                                            <Form.Label>levelAuth
                                                <OverlayTrigger
                                                    placement="right"
                                                    overlay={this.renderTooltip('Scegli il livello di autorizzazione.')}
                                                >
                                                    <InfoCircle className="ml-2" style={{ cursor: 'pointer', marginLeft: '5px' }} />
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <Form.Select
                                                className='formEditUserMod'
                                                size="sm"
                                                name="levelAuth"
                                                value={this.state.levelAuth}
                                                onChange={this.handleInputChange}
                                            >
                                                <option value={-1}>Superadmin</option>
                                                <option value={0}>Capo Stabilimento</option>
                                                <option value={1}>Capo Reparto</option>
                                                <option value={2}>Postazione</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="REPARTO">
                                            <Form.Label>reparto</Form.Label>
                                            <Form.Select
                                                className='formEditUserMod'
                                                size="sm"
                                                type="text"
                                                name="REPARTO"
                                                value={this.state.REPARTO}
                                                onChange={this.handleInputChange}
                                            >
                                                {opzioniReparto.map((opzione) => (
                                                    <option key={opzione} value={opzione}>{opzione}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {/* Ripeti per i restanti campi, adattando il componente Form.Group come fatto sopra */}

                                {/* Gestione POSTAZIONE dinamica */}
                                <Form.Label>POSTAZIONE</Form.Label>
                                {this.state.POSTAZIONE.map((postazione, index) => (
                                    <Row key={index} style={{ justifyContent: 'space-around', marginBottom: '1%' }}>
                                        <Col >
                                            <Form.Control
                                                type="text"
                                                value={postazione}
                                                onChange={(e) => this.handlePostazioneChange(index, e.target.value)}
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <Button className='deleteButton' variant="danger" onClick={() => this.handleRemovePostazione(index)}>Rimuovi</Button>
                                        </Col>
                                    </Row>
                                ))}
                                <Button className='confirmButton' onClick={this.handleAddPostazione} >Aggiungi Postazione</Button>
                            </Card.Body>
                        </Card>
                    ))}
                    <div style={{display:'flex', justifyContent:'flex-end'}}>
                        <Button className='cancelButton' variant='warning' onClick={() => this.getBack()}  >Annulla</Button>
                        <Button className='confirmButton' type="submit" >Aggiungi Utente</Button>
                    </div>
                </Form>
            </Container>
        );
    }
}

export default withTranslation()(UserForm);


export function UserFormWrapper() {
    const params = useParams(); // Ottieni i parametri dell'URL, ad es. { username: "nome_utente" }
    let navigate = useNavigate();
    return <UserForm params={params} navigate={navigate} />;
}


