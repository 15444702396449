
import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import SiteMaintenance from '../components/img/Site_maintenance.png';
import { Flex } from '@aws-amplify/ui-react';
import { getPersonalUserInfo } from '../components/Functions/PersonalUserInfo';


class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: null,
      livello: null,
    };
  }

  async componentDidMount() {
    try {
      const user = await getPersonalUserInfo();
      let l = null;
      if (user.livello === "0") {
        l = "ADMIN - responsabile stabilimento";
      } else if (user.livello === "1") {
        l = "Responsabile Reparto";
      } else if (user.livello === "2") {
        l = "Gestione Postazione";
      }
      console.log("l", l);
      this.setState({ userInfo: user, livello: l });
    } catch (error) {
      // Gestisci l'errore
    }
  }

  render() {
    const { userInfo, livello } = this.state;
    const { t } = this.props;


    /*return (
      <div>
        {userInfo ? (
          <div>
            <div>
              <h1 style={{ textAlign: "center" }}>Stabilimento: {userInfo.stabilimento}</h1>
              <h2 style={{ marginTop: "2rem" }}>{livello}</h2>

              {userInfo.livello === "1" && (
                <div>
                  <h3>Reparto: {userInfo.reparto}</h3>
                </div>
              )}
            </div>
            <p>Username: {userInfo.username}</p>
            {userInfo.livello <= 1 && (
              <div>
                <p>Nome: {userInfo.name}</p>
                <p>Cognome: {userInfo.surname}</p>
              </div>
            )}
            {userInfo.livello === "2" && (
              <div>
                <p>Postazione:</p>
                <ul>
                  {userInfo.postazione.map((postazione, index) => (
                    <li key={index}>{postazione}</li>
                  ))}
                </ul>
              </div>)}

          </div>
        ) : (
          <p>Caricamento delle informazioni utente...</p>
        )}
      </div>
    );*/

    return (
      <div style={{ width: '100%', height: '100%', display: 'flex', flexFlow: 'column', justifyContent: 'center' }}>
        <h1 style={{ textAlign: 'center' }}>
          {t('This site is currently under maintenance')}
        </h1>
        <div style={{ display: 'flex', height: '70%', justifyContent: 'center', padding: '5%',opacity:'30%' }}>
          <img src={SiteMaintenance} alt='' />
        </div>
      </div>

    );
  }


}

export default withTranslation()(Profile);


