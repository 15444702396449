//import { Image, useTheme } from "@aws-amplify/ui-react";
//import logo from '../img/laiotech_r_lab2.png';
//import bottegaLogo from '../img/logoBV.jpg';
//import remira from '../img/REMIRA_Logo_Claim_white-1.png';


export function Header() {
  

  return (
    <div>
    
    </div>
  );
}
