import './App.css';
import React, { Component } from "react";

import { BrowserRouter as Router, Route, Routes, Navigate, Outlet, useNavigate } from "react-router-dom";

import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react';

import i18n from 'i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import { RequireAuth } from './RequireAuth';
/*LOGIN*/
import { Login } from './components/login/Login';
import { Header } from './components/login/Header';
import { SignInHeader } from './components/login/SignInHeader';
import { SignInFooter } from './components/login/SignInFooter';
import { Footer } from './components/login/Footer';
/*PAGES */

/*
import Home from './pages/Home/Home';
import Dashboard from './pages/Dashboard/Dashboard';

import Macchine from './pages/Macchine/Macchine';*/
import Profile from './pages/Profile';
import Loading from './pages/Loading';
//import Map from './pages/Map/Map';
import { Layout } from "./pages/Layout";


/*COMPONENTS & FUNCTIONS */

//import { getUserConfigSys, getUserInfo } from './components/UserInfo';
import { Amplify, Auth, Hub } from 'aws-amplify';
//import { attachIoTPolicy } from './components/Iot_Polycy';
//import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
//import { PubSub } from 'aws-amplify';


import { Card } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { getPersonalUserInfo, getUserConfigSys } from './components/Functions/PersonalUserInfo';



//import configParams from './configParams.json';
import Notifications from './pages/Settings/Notifications/Notifications';
import UsersWrapper from './pages/Settings/Users/Users';
import { UserDetailWrapper } from './pages/UserDetail/UserDetail';
import NewUserForm, { UserFormWrapper } from './pages/UserDetail/NewUser/NewUserForm';
import Home from './pages/Home/Home';




class MyRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,


      factorySet: null,
      factoryIndex: null,
      livelloAuth: null,
      configSys: null,

      isAuthenticated: false,
      isLoading: true,

      userInfo: null,

    };

  }
  componentWillUnmount() {
    try {

    } catch (error) {
      console.log(error);
    }

  }

  async componentDidMount() {
    try {
      var factorySet = null;
      var factoryIndex = null;
      var livelloAuth = null;
      var userFactorySettings = null;

      var user = await getPersonalUserInfo();


      i18n.changeLanguage(user.configUser.userSettings.lang);

      var userConfigSys = await getUserConfigSys();
      factorySet = userConfigSys.settings.factorySet;


      if (userConfigSys.factories.length >= 1) {
        factoryIndex = userConfigSys.factories.findIndex(factory => factorySet in factory);
        if (factoryIndex === -1) {
          console.log('factory', factorySet, 'did not found');
          /**-------------------------------------------------------DA COMPLETARE */
        } else {  //se abbiamo una factory != -1
          userFactorySettings = userConfigSys.factories[factoryIndex];

          //livello auth utente
          livelloAuth = parseInt(userFactorySettings[factorySet].levelAuth, 10);


          /*  ATTACH IoT POLICY */

          /**VERSIONE AGGIONARA IN RELEASE 2.0.0 CON NUOVO SETUP DI CONFIG UTENTE IN COGNITO */
          /*
          if (userFactorySettings[factorySet].policyMqtt === 0) {
            await attachIoTPolicy(userFactorySettings, factorySet, factoryIndex);
            user = await getPersonalUserInfo();///////----------------------------------MODIFICARE manca config Sys???
            userConfigSys = await getUserConfigSys();
          }*/



          this.setState({

            userInfo: user,
            user,
            livelloAuth,
            isLoading: false,

            configSys: userFactorySettings[factorySet],
            factorySet
          });
        }
      } else {
        console.log('NO FACTORIES CONFIGURED');     ///////////----------------------------------------------
      }


      this.setState({ isLoading: false })

    } catch (error) {
      console.error(error);
      Auth.signOut();///???----------------------------------------------/***----------------------AGGIUNGERE DELETE COOKIES */
    }

  }




  render() {

    const { userInfo, livelloAuth, configSys, factorySet } = this.state;
    const { isLoading } = this.state;


    if (isLoading) {
      return <Loading />
    }

    return (

      <Router>

        <Routes>
          <Route path="/login" element={<Login />} />

          <Route path="/" element={<LayoutWrapper user={userInfo} client={userInfo.configUser.client} factory={factorySet} livelloAuth={livelloAuth} />}>

            <Route path="/"

              element={<Home />}



            />
            <Route path="/Users/:username"
              element={livelloAuth <= -1 ? (
                <UserDetailWrapper />
              ) : (
                <Navigate to="/" replace />
              )}
            />

            <Route
              path="/profile"
              element={livelloAuth <= -1 ? (
                <Profile />
              ) : (
                <Navigate to="/" replace />
              )}
              loader={this.componentDidMount}
            />

            <Route
              path={`/Settings/Notifications`}

              element={livelloAuth <= -1 ? (
                <Notifications />
              ) : (
                <Navigate to="/" replace />
              )}
              loader={this.componentDidMount}
            />
            <Route
              path={`/Users`}

              element={livelloAuth <= -1 ? (
                <UsersWrapper />
              ) : (
                <Navigate to="/" replace />
              )}
              loader={this.componentDidMount}
            />
            <Route
              path={'/Users/newUser'}
              element={livelloAuth <= -1 ? (
                <UserFormWrapper />
              ) : (
                <Navigate to="/" replace />
              )}
            />
          </Route>

        </Routes>

      </Router >

    );
  }
}


function LayoutWrapper({ user, client, factory, livelloAuth }) {
  //const { darkMode } = useContext(DarkModeContext);
  //console.log("user", user);

  return (
    <Layout user={user} client={client} factory={factory} livelloAuth={livelloAuth}>
      <Card className={`mainCard`}> {/** className={`mainCard ${darkMode ? 'dark-mode' : ''}`} */}
        <Outlet /> {/* <Outlet> è utilizzato per renderizzare le route figlie */}
        <ToastContainer
          position="bottom-right"
          theme="colored"
        />
      </Card>
    </Layout>
  );
}


function isUserEmpty(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false; // L'oggetto ha almeno una chiave, quindi non è vuoto
    }
  }
  return true; // L'oggetto è vuoto
}

class App extends Component {
  constructor(props) {
    super(props);
    this.checkAuthInterval = null;

  }

  componentDidMount() {

    // Controlla l'autenticazione ogni tot secondi 600000 = 10 minuti
    this.checkAuthInterval = setInterval(this.checkAuthentication, 600000);
  }

  componentWillUnmount() {
    // Cancella l'intervallo quando il componente viene smontato
    clearInterval(this.checkAuthInterval);
  }

  async checkAuthentication() {
    try {
      const user = await Auth.currentUserInfo();

      //console.log(user);
      if (isUserEmpty(user)) {
        Auth.signOut();
      }
      // L'utente è autenticato, puoi eseguire ulteriori azioni se necessario
    } catch (error) {
      // Errore o l'utente non è autenticato, quindi esegui il logout
      //Auth.signOut();
      console.error(error);
    }
  }

  render() {
    return (
      <Authenticator>
        {({ signOut, user }) => (
          <MyRoutes info={user} />
        )}
      </Authenticator>
    );
  }
}

export default withAuthenticator(withTranslation()(App), {
  hideSignUp: true,
  components: {

    Header,
    SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter
    },
    Footer
  }
});


