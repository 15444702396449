import { Heading, useTheme, Image } from "@aws-amplify/ui-react";
import logo from '../img/laiotech_r_lab2.png';
//import logo from '../img/laiotech_lab.png';
import bottegaLogo from '../img/logoBV.jpg';
//import remira from '../img/REMIRA_Logo_Claim.png';
import logoSvg from '../img/laiotech_logo.svg'; 



export function SignInHeader() {
  const { tokens } = useTheme();

  return (
    <Heading level={3} padding={`${tokens.space.xl} ${tokens.space.xl} 0`}>
      <div>
        <Image
          alt="Laiotech"
          width="75%"
          height="auto"
          display={'Flex'}
          margin={'auto'}
          src={logoSvg}
          padding={tokens.space.xxs}
        />
        {/*
        <Image
          alt="remira"
          src={remira}
          width="40%"
          height="auto"
          marginBottom={'4%'}
          style={{ float: 'right' }}
          padding={tokens.space.xxxs}
        />*/}
        <Image
          alt="Bottega"
          src={bottegaLogo}
          padding={tokens.space.xxs}
        />
      </div>
      <div className="login-text">Factory Machine Monitoring</div>
      
    </Heading>
  );
}


