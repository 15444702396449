import React from "react";
import { Spinner } from 'react-bootstrap';

function Loading() {


    return(
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div style={{ textAlign: 'center' }}>
                <Spinner animation="border" role="status" style={{ width: '5rem', height: '5rem', color: '#003057', borderWidth: '0.5rem'}}>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                <p style={{ marginTop: '1rem' }}>Loading...</p>
            </div>
        </div>
    );

    

} export default Loading;