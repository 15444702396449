
import configParams from '../../configParams.json';
import { Auth } from 'aws-amplify';
import awsconfig from '../../aws-exports'; // o il percorso relativo al tuo file di configurazione
import { toast } from 'react-toastify';

const s3BucketName = awsconfig.aws_user_files_s3_bucket;
export async function saveJsontos3(fileName, content, path) {
    try {
        var URL = "";
        if (configParams.Config.AMBIENTE === "dev") {
            URL = configParams.Config.api_save_Json_to_s3_dev;
        } else if (configParams.Config.AMBIENTE === "prod") {
            URL = configParams.Config.api_save_Json_to_s3_prod;
        }
        //console.log(URL);

        var session = await Auth.currentSession();
        
        var token = session.getIdToken().jwtToken;
        var myHeaders = new Headers();
        myHeaders.append("token", token);
        var raw = JSON.stringify({
            "fileName": fileName,
            "content": content,
            "path": path,
            "bucket": s3BucketName
        })

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const response = await fetch(URL, requestOptions);
        const result = await response.text();
        const res = JSON.parse(result);
        const res2 = JSON.parse(res.body);
        toast.success('Dati inviati con successo!');
        return res2;
    } catch (error) {
        console.error(error);
        toast.error('Errore nell invio dei dati.', error);
        throw error;
    }
}