import { PubSub, Storage } from 'aws-amplify';



/*SCARICA UN FILE DI TESTO DA S3 */
export function getS3File(file) {
  return new Promise(async (resolve, reject) => {
    try {
      const f = await Storage.get(file, {
        level: 'protected',
        identityId: 'Config', // path di S3
        download: true,
        cacheControl: 'no-cache' 
      });

      f.Body.text().then((string) => {
        //console.log(string);
        resolve(string);
      });

    } catch (error) {
      console.log(error)
      reject(error);

    }
  });
}

/*SCARICA UN IMMAGINE DA S3 */
export function getS3Image(file) {
  return new Promise(async (resolve, reject) => {
    try {
      const f = await Storage.get(file, {
        level: 'protected',
        identityId: 'Config', // path di S3
        download: true,
      });

      const reader = new FileReader();
      reader.onloadend = () => {
        const imageData = reader.result;

        resolve(imageData);

      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(f.Body);
    } catch (error) {
      reject(error);
    }
  });
}


export function uploadJsonToS3(jsonObject, fileName) {
  return new Promise(async (resolve, reject) => {
    try {
      // Converti l'oggetto JSON in una stringa
      const jsonString = JSON.stringify(jsonObject);

      // Imposta le opzioni di caricamento
      const uploadOptions = {
        level: 'protected', // o 'public', 'private'
        contentType: 'application/json', // Imposta il tipo di contenuto a JSON
        identityId: 'Config', // Utilizzato per i percorsi protetti, se necessario
      };

      // Carica la stringa JSON su S3
      const result = await Storage.put(fileName, jsonString, uploadOptions);

      // Costruisci l'URL del file caricato, se necessario.
      //const url = await Storage.get(result.key, { level: uploadOptions.level });

      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
}

export function getLatestS3Files(folderPath, fileCount, pageSize) {
  return new Promise(async (resolve, reject) => {
    try {
      const files = await Storage.list(folderPath, {
        level: 'protected',
        identityId: 'oreLavoroMacchine',
        pageSize: 'ALL'
      });
      //pageSize: pageSize,


      // Sort the files by descending order of the last modified timestamp
      files.results.sort((a, b) => b.lastModified - a.lastModified);

      // Filter the files to get only the .json files
      const jsonFiles = files.results.filter(file => file.key.endsWith('.json'));

      // Get the first `fileCount` files
      const latestFiles = jsonFiles.slice(0, fileCount);

      // Download and resolve the contents of the latest files
      const fileContents = await Promise.all(
        latestFiles.map(file => Storage.get(file.key, {
          level: 'protected',
          identityId: 'oreLavoroMacchine',
          download: true,
        }))
      );

      const fileContentsTexts = await Promise.all(
        fileContents.map(file => file.Body.text())
      );

      resolve(fileContentsTexts);
    } catch (error) {
      reject(error);
    }
  });
}



