import React, { useState } from 'react';
import { FaBars, FaTh } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
//import '../App.css';
import "./Layout.css";
import { BsFillHouseFill, BsFillPersonFill } from 'react-icons/bs';

//import { GiSewingMachine } from "react-icons/gi";
import { Col, Dropdown, Button, Row, ToggleButton } from 'react-bootstrap';
//import Button from 'react-bootstrap/Button';

//import infoEventi from "../components/img/info_eventi.svg";
//import { TbListSearch } from "react-icons/tb";
import { MdOutlineQueryStats, MdOutlinePrecisionManufacturing } from "react-icons/md";
import { SlMap } from "react-icons/sl";
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { BrowserView, MobileView } from 'react-device-detect';


//import laiotechLogo from '../components/img/laiotech_r_lab2.png';
import laiotechLogo from '../components/img/laiotech_logo.svg';

import brand from '../components/img/logoBV.jpg';
//import brand from '../components/img/laiotech_r_lab2.png';
//import laiotechLogo from '../components/img/REMIRA_Logo_Claim.webp';
import { PiFactory } from "react-icons/pi";

import Navbar from 'react-bootstrap/Navbar';
import LanguageSelector from '../components/translation/LanguageSelector';

import { useTranslation } from 'react-i18next';
import configJson from '../configParams.json';

import { IoNotifications } from "react-icons/io5";
import { FaUsersGear } from "react-icons/fa6";
import { RiUserAddFill } from "react-icons/ri";

export function Layout(props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const navigate = useNavigate();

  function logOut() {
    signOut();
    navigate('/login');
  }

  const menuPages = [
    {
      path: '/',
      name: 'Home',
      icon: <BsFillHouseFill />,
      level: 2
    },
    {
      path: '/Users',
      name: 'Impostazioni Utenti',
      icon: <FaUsersGear size={22} />,
      level: -1

    },

    {
      path: '/Settings/Notifications',
      name: 'Impostazioni Notifiche',
      icon: <IoNotifications size={22}/>,
      level: -1

    },

    {
      path: '/Users/newUser',
      name: 'Crea nuovo utente',
      icon: <RiUserAddFill size={22} />,
      level: -1

    }

  ];

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const SettingsDropdown = (
    <Dropdown.Item onClick={() => navigate('/Settings/Notifications')}>
      {t('Notifications settings')}
    </Dropdown.Item>
  );
  const profileDropdown = (
    <Dropdown.Item onClick={() => navigate('/profile')}>
      {t('Account')}
    </Dropdown.Item>
  );

  const logoutDropdown = (
    <Dropdown.Item onClick={() => logOut()}>
      {t('Logout')}
    </Dropdown.Item>
  );
  //console.log('props', props);
  return (
    <>
      <div className="container_new" style={{ display: 'flex', flexFlow: 'column' }}>{/*className="container_new"*/}
        {/*<div style={{ width: '100%', height: '100%', display: 'flex', transition: 'all 0.5s' }}>*/}
        {/*<Row  style={{ margin: 0, padding: 0,  }}>*/}

        <Navbar fixed="top" style={{ display: 'flex', position: 'sticky', width: '100%', margin: 0, padding: 5, boxShadow: 'rgba(0, 0, 0, 0.25) 0px 4px 4px ', justifyContent: 'space-between' }}>
          {/*<nav>*/}
          {/*HEADER */}
          {/* <div className="navbar" > */}{/*style={{ padding: "1%" }} */}
          <Col className='col-md-2' style={{ flex: 'unset' }}>
            <NavLink to="/" className="logo">
              <img src={laiotechLogo} alt='Laiotech' style={{ width: "60%", height: "auto" }} />
            </NavLink>
          </Col>
          <Col style={{ display: 'flex', justifyContent: 'center' }}>
            {/*<img src={brand} alt='brand' style={{ width: "33vw", height: "auto", marginLeft: "auto", marginRight: "auto" }} />*/}
            <span style={{ fontSize: 'large', fontWeight: 'bold', }}>Factory Machine Monitorning Manager</span>
          </Col>
          <Col className='col-md-6' style={{ marginLeft: 'auto', width: 'auto' }} > {/*, display: 'flex', flexFlow: 'row', alignItems: 'center' */}
            <Row >
              <Col style={{ width: 'auto', padding: '0', alignSelf: 'center' }}>
                <span style={{ display: 'inline', fontWeight: 'bold' }}>{configJson.Config.AMBIENTE === 'dev' && configJson.Config.AMBIENTE}</span>

              </Col>

              <Col style={{ width: 'auto', padding: '0' }}>

                <LanguageSelector />
              </Col>
              <Col className="col-md-4" style={{ borderRight: '1px solid #000', width: 'auto' }}>

                <Button className='btn-secondary btn-light' style={{ backgroundColor: 'unset', borderColor: 'none', fontSize: 'small', fontWeight: 'bold', display: 'flex', alignItems: 'center' }} >  {/*onClick={() => navigate('/choose-factory')}*/}
                  <PiFactory size={20} style={{ marginRight: '5px' }} />
                  {props.factory}
                </Button>

              </Col>
              <Col style={{ padding: '0', marginLeft: '10px', fontSize: 'x-small', whiteSpace: 'nowrap', textAlign: 'center' }}>
                <span style={{ display: 'inline', fontWeight: 'bold' }}>{props.user.name !== undefined && props.user.name} </span>
                <span style={{ display: 'inline', fontWeight: 'bold' }}>{props.user.surname !== undefined && props.user.surname}</span>
                <div>{props.client}</div>
              </Col>
              <Col >
                {/*TASTO DESTRO LOGIN LOGOUT */}
                {route !== 'authenticated' ? (
                  <Button style={{ backgroundColor: '#003057', borderColor: '#003057' }} onClick={() => navigate('/login')}>Login</Button>
                ) : (
                  <Dropdown className='profileButton' style={{ marginRight: "10px", width: "auto", padding: '3', display: 'flex', justifyContent: 'center' }}>
                    <BrowserView>
                      <Dropdown.Toggle style={{ fontSize: '0.75rem', padding: '6px ' }}>
                        {props.user.name[0]}{props.user.surname[0]}
                        {/*<BsFillPersonFill />*/}
                        {/**------------------ */}
                      </Dropdown.Toggle>
                    </BrowserView>
                    <MobileView>
                      <Dropdown.Toggle style={{ fontSize: '0.75rem', padding: '6px' }}>
                        {/* <BsFillPersonFill size={14} />*/}
                        {props.user.name[0]}{props.user.surname[0]}
                      </Dropdown.Toggle>
                    </MobileView>
                    <Dropdown.Menu align={'end'} style={{ fontSize: '1rem' }}>
                      <Dropdown.Item>{profileDropdown}</Dropdown.Item>
                      <Dropdown.Item>{logoutDropdown}</Dropdown.Item>
                    </Dropdown.Menu>

                  </Dropdown>
                )}
              </Col>
            </Row>
          </Col>

          {/*</div>*/}
          {/*</nav >*/}
        </Navbar>

        {/*</Row>*/}
        {/*<Row  style={{ margin: 0, padding: 0, }}>*/}
        <div id='principale' style={{ display: 'flex', flexDirection: 'row', height: '100%', flex: 1, overflow: 'auto' }}>
          <Col className='col-2' style={{ zIndex: '1', width: 'auto', padding: 0 }} >{/**className="container-2" */}
            <div
              style={{ width: isOpen ? '10rem' : '50px' }}
              className="sidebar"
            >
              <div className="top_section">
                <h1
                  style={{ display: isOpen ? 'block' : 'none', textAlign: "center" }}
                  className="logo"
                >
                  {/**-----------CORREGGERE */}
                </h1>
                <div
                  style={{
                    marginLeft: isOpen ? 'auto' : '0px'
                  }}
                  className="bars"
                >
                  <FaBars onClick={toggle} />
                </div>
              </div>
              {menuPages.map((item, index) => {

                if (item.level >= props.livelloAuth) {
                  return (
                    <NavLink
                      to={item.path}
                      key={index}
                      className="link"
                      activeclassname="active"
                      onClick={() => setIsOpen(false)}

                    >
                      <div className="icon">{item.icon}</div>
                      <div
                        style={{
                          fontSize: 'normal',
                          display: isOpen ? 'block' : 'none'
                        }}
                        className="link_text"
                      >
                        {t(item.name)}
                      </div>
                    </NavLink>);
                }
                return null;
              })}
            </div>

          </Col>
          <Col className='col-10 ' style={{ flex: 1, margin: 0, padding: 0 }}>
            <main className='main'>{props.children}</main>
          </Col>
          {/*</Row>*/}
        </div>

      </div>
      {/*</div>*/}
      {/*<Outlet />*/}
    </>
  );
}