// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationIT from './it/translation.json';
import translationEN from './en/translation.json';
import translationUS from './us/translation.json';

// Le risorse di traduzione potrebbero essere file JSON che contengono le chiavi e le traduzioni
const resources = {
  it: {
    translation: translationIT
  },
  IT: {
    translation: translationIT
  },
  en: {
    translation: translationEN
  },
  EN: {
    translation: translationEN
  },
  us: {
    translation: translationUS
  },
  US: {
    translation: translationUS
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'it', // lingua di default
    keySeparator: false, // possiamo usare punti nei nostri file di traduzione
    interpolation: {
      escapeValue: false, // non necessario per react poiché sfugge al valore di default
    },
  });

export default i18n;
