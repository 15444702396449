import React, { Component } from 'react'
import { Button, Card, Form, Table } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { DeleteUser } from '../../../components/Functions/GetUsersInfo';
import { Navigate } from 'react-router-dom';

export class UserMod extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: [false, false],
            editedUser: JSON.parse(JSON.stringify(props.user)),
            editedConfigSys: JSON.parse(JSON.stringify(props.configSys)),
            editedConfigUser: JSON.parse(JSON.stringify(props.configUser))
        };
    }


    toggleEdit = (index) => {
        this.setState(prevState => ({
            ...prevState,
            isEditing: prevState.isEditing.map((edit, i) => i === index ? !edit : edit),
        }), () => {
            if (!this.state.isEditing[index]) {
                let newUser = JSON.parse(JSON.stringify(this.state.editedUser));
                newUser.Attributes.find(attr => attr.Name === 'custom:configSys').Value = JSON.stringify(this.state.editedConfigSys);
                newUser.Attributes.find(attr => attr.Name === 'custom:configUser').Value = JSON.stringify(this.state.editedConfigUser);
                this.props.sendData(newUser);
            }
        });
    };

    cancelEdit = (index) => {
        this.setState({
            isEditing: this.state.isEditing.map((edit, i) => i === index ? false : edit),
            editedUser: JSON.parse(JSON.stringify(this.props.user)),
            editedConfigSys: JSON.parse(JSON.stringify(this.props.configSys)),
            editedConfigUser: JSON.parse(JSON.stringify(this.props.configUser)),
        });
    };


    handleInputChange = (type, name, value) => {
        this.setState(prevState => {
            const newState = { ...prevState };

            if (type === 'user') {
                const attrIndex = newState.editedUser.Attributes.findIndex(attr => attr.Name === name);
                if (attrIndex !== -1) {
                    newState.editedUser.Attributes[attrIndex].Value = value;
                }
            } else if (type === 'configUser') {
                // Gestione delle proprietà al primo livello di editedConfigUser
                if (name === 'client' || name === 'clientCode') {
                    newState.editedConfigUser[name] = value;
                }
                // Gestione delle proprietà annidate dentro userSettings
                else if (name in newState.editedConfigUser.userSettings) {
                    newState.editedConfigUser.userSettings[name] = value;
                }
            } else if (type === 'configSys') {
                newState.editedConfigSys.FMM.settings[name] = value;
            }

            return newState;
        });
    };
    deleteUser = async () => {
        if (window.confirm('Sei sicuro di voler eliminare questo utente?')) {
            try {
                // Chiama la funzione/API per eliminare l'utente
                await DeleteUser(this.props.user.Username);
                

                // Gestisci la risposta (es. mostra un messaggio di successo, reindirizza l'utente, ecc.)
                console.log('Utente eliminato con successo');
                this.props.navigate(-1);
            } catch (error) {
                console.error('Errore nell\'eliminazione dell\'utente:', error);
            }
        }
    };


    renderUserCard() {
        const { isEditing, editedUser, editedConfigUser } = this.state;
        return (
            <Card key="user-info" className='cardModifiche'>
                <Card.Header>
                    <div className='titleCard'>Informazioni generali utente</div>
                    {isEditing[0] ? (
                        <>
                            <Button className='confirmButton' onClick={() => this.toggleEdit(0)} style={{ float: 'right', marginLeft: '10px' }}>
                                Conferma Modifica
                            </Button>
                            <Button className='cancelButton' variant='warning' onClick={() => this.cancelEdit(0)} style={{ float: 'right' }}>
                                Annulla Modifiche
                            </Button>
                        </>
                    ) : (
                        <Button className='modButton' onClick={() => this.toggleEdit(0)} style={{ float: 'right' }}>
                            Modifica
                        </Button>
                    )}
                </Card.Header>
                <Card.Body style={{ display: 'flex', justifyContent: 'space-between', fontSize: 'small' }}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Cognome</th>
                                <th>Email</th>
                                <th>Cliente</th>
                                <th>Codice Cliente</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {isEditing[0] ? (
                                        <Form.Control
                                            className='formEditUserMod'
                                            type="text"
                                            value={editedUser.Attributes.find(attr => attr.Name === 'name')?.Value || ''}
                                            onChange={(e) => this.handleInputChange('user', 'name', e.target.value)}
                                        />
                                    ) : (
                                        editedUser.Attributes.find(attr => attr.Name === 'name')?.Value || 'N/A'
                                    )}
                                </td>
                                <td>
                                    {isEditing[0] ? (
                                        <Form.Control
                                            className='formEditUserMod'
                                            type="text"
                                            value={editedUser.Attributes.find(attr => attr.Name === 'family_name')?.Value || ''}
                                            onChange={(e) => this.handleInputChange('user', 'family_name', e.target.value)}
                                        />
                                    ) : (
                                        editedUser.Attributes.find(attr => attr.Name === 'family_name')?.Value || 'N/A'
                                    )}
                                </td>
                                <td>
                                    {isEditing[0] ? (
                                        <Form.Control
                                            className='formEditUserMod'
                                            type="text"
                                            value={editedUser.Attributes.find(attr => attr.Name === 'email')?.Value || ''}
                                            onChange={(e) => this.handleInputChange('user', 'email', e.target.value)}
                                        />
                                    ) : (
                                        editedUser.Attributes.find(attr => attr.Name === 'email')?.Value || 'N/A'
                                    )}
                                </td>
                                <td>
                                    {isEditing[0] ? (
                                        <Form.Control
                                            className='formEditUserMod'
                                            type="text"
                                            value={editedConfigUser.client || ''}
                                        //onChange={(e) => this.handleInputChange('configUser', 'client', e.target.value)}
                                        />
                                    ) : (
                                        editedConfigUser.client || ''
                                    )}
                                </td>
                                <td>
                                    {isEditing[0] ? (
                                        <Form.Control
                                            className='formEditUserMod'
                                            type="text"
                                            value={editedConfigUser.clientCode || ''}
                                        //onChange={(e) => this.handleInputChange('configUser', 'clientCode', e.target.value)}
                                        />
                                    ) : (
                                        editedConfigUser.clientCode || ''
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    }


    renderConfigUserCard() {
        const { isEditing, editedConfigUser, editedConfigSys } = this.state;
        const factoryOptions = editedConfigSys.FMM.factories.map(factory => Object.keys(factory)[0]);
        const opzioniLingua = ['IT', 'EN', 'US'];
        return (
            <Card key="user-config" className='cardModifiche'>
                <Card.Header>
                    <div className='titleCard'>Configurazione utente personali</div>
                    {isEditing[1] ? (
                        <>
                            <Button className='confirmButton' onClick={() => this.toggleEdit(1)} style={{ float: 'right', marginLeft: '10px' }}>
                                Conferma Modifica
                            </Button>
                            <Button className='cancelButton' variant='warning' onClick={() => this.cancelEdit(1)} style={{ float: 'right' }}>
                                Annulla Modifiche
                            </Button>
                        </>
                    ) : (
                        <Button className='modButton' onClick={() => this.toggleEdit(1)} style={{ float: 'right' }}>
                            Modifica
                        </Button>
                    )}
                </Card.Header>
                <Card.Body style={{ display: 'flex', justifyContent: 'space-between', fontSize: 'small' }}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Lingua</th>
                                <th>Default App</th>
                                <th>Default factory set</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {isEditing[1] ? (
                                        <Form.Select
                                            className='formEditUserMod'
                                            type="text"
                                            value={editedConfigUser.userSettings.lang}
                                            onChange={(e) => this.handleInputChange('configUser', 'lang', e.target.value)}
                                        >
                                            {opzioniLingua.map((opzione) => (
                                                <option key={opzione} value={opzione}>{opzione}</option>
                                            ))}
                                        </Form.Select>
                                    ) : (
                                        editedConfigUser.userSettings.lang || 'N/A'
                                    )}
                                </td>
                                <td>
                                    {isEditing[1] ? (
                                        <Form.Control
                                            className='formEditUserMod'
                                            type="text"
                                            value={editedConfigUser.userSettings.favApp}
                                        //onChange={(e) => this.handleInputChange('configUser', 'favApp', e.target.value)}
                                        />
                                    ) : (
                                        editedConfigUser.userSettings.favApp || 'N/A'
                                    )}
                                </td>
                                <td>
                                    {isEditing[1] ? (
                                        <Form.Select
                                            className='formEditUserMod'
                                            value={editedConfigSys.FMM.settings.factorySet}
                                            onChange={(e) => this.handleInputChange('configSys', 'factorySet', e.target.value)}
                                        >
                                            {factoryOptions.map((option) => (
                                                <option key={option} value={option}>{option}</option>
                                            ))}
                                        </Form.Select>
                                    ) : (
                                        editedConfigSys.FMM.settings.factorySet || 'N/A'
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    }


    render() {
        const { user, configUser, configSys } = this.props;
        const { isEditing } = this.state;
        return (
            <>
                <div>
                    <h2>Dettagli Utente: {user.Username}</h2>
                    <Button
                        className='cancelButton'
                        variant="danger"
                        onClick={this.deleteUser}
                        style={{ position: 'absolute', top: 0, right: 0, marginTop: '10px', marginRight: '10px' }}
                    >
                        Elimina Utente
                    </Button>
                </div>
                {this.renderUserCard()}
                {this.renderConfigUserCard()}
            </>
        )
    }
}

export default withTranslation()(UserMod)