import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import ReactCountryFlag from "react-country-flag";
import i18n from 'i18next';

const LanguageSelector = () => {
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

    useEffect(() => {
        const handleLanguageChange = (lng) => {
            setCurrentLanguage(lng);
        };

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, []);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        console.log(lng);
    };

    const getCountryCode = (language) => {
        switch (language) {
            case 'it':
                return 'IT';
            case 'en':
                return 'GB'; // o 'US' a seconda delle tue preferenze
            case 'us':
                return 'US';
            case 'IT':
                return 'IT';
            case 'EN':
                return 'GB'; // o 'US' a seconda delle tue preferenze
            case 'US':
                return 'US';
            default:
                return 'IT'; // Default language
        }
    };
    console.log('currentLanguage', currentLanguage);
    return (
        <Dropdown className='languageButton' style={{ width: "auto", padding: '0' }}>
            <Dropdown.Toggle style={{ flexFlow: 'row-reverse' }}>
                <ReactCountryFlag countryCode={getCountryCode(currentLanguage)}
                    svg
                    style={{
                        width: '1.2em',
                        height: '1.2em',
                        verticalAlign: 'middle',

                    }} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item style={{ fontSize: 'small', display: 'flex' }} onClick={() => changeLanguage('it')}>
                    <div style={{ marginRight: '15px' }}>
                        <ReactCountryFlag countryCode="IT" svg style={{
                            width: '1.2em',
                            height: '1.2em',

                        }} />
                    </div>
                    <div style={{ textAlign: 'center' }}>Ita</div>
                </Dropdown.Item>
                <Dropdown.Item style={{ fontSize: 'small', display: 'flex' }} onClick={() => changeLanguage('en')}>
                    <div style={{ marginRight: '15px' }}>
                        <ReactCountryFlag countryCode="GB" svg style={{
                            width: '1.2em',
                            height: '1.2em',

                        }} />
                    </div>
                    <div style={{ textAlign: 'center' }}>English</div>
                </Dropdown.Item>
                <Dropdown.Item style={{ fontSize: 'small', display: 'flex' }} onClick={() => changeLanguage('us')}>
                    <div style={{ marginRight: '15px' }}>
                        <ReactCountryFlag countryCode="US" svg style={{
                            width: '1.2em',
                            height: '1.2em',

                        }} />
                    </div>
                    <div style={{ textAlign: 'center' }}>USA</div>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>

    );
};

export default LanguageSelector;
