import { Auth } from 'aws-amplify';
import configParams from '../../configParams.json';
import { toast } from 'react-toastify';


export async function GetMachine_lastStatus() {
    const msg = {
        "IDM": 0,
        "IDS": "Malo",
        "IDR": [
          "TaglioPelle"
        ],
        "UC": [
          "ALL"
        ],
        "IDO": "MANAGER",
        "LIV": -1
      };


      try {
        var URL = "";
        if (configParams.Config.AMBIENTE === "dev") {
            URL = configParams.Config.api_GET_MACHINE_LAST_STATUS_dev;
        } else if (configParams.Config.AMBIENTE === "prod") {
            URL = configParams.Config.api_GET_MACHINE_LAST_STATUS_prod;
        }
        //console.log(URL);

        var session = await Auth.currentSession();
        
        var token = session.getIdToken().jwtToken;
        var myHeaders = new Headers();
        myHeaders.append("token", token);
        var raw = JSON.stringify(msg)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const response = await fetch(URL, requestOptions);
        const result = await response.text();
        const res = JSON.parse(result);
        const res2 = JSON.parse(res.body);
       
        return res2;
    } catch (error) {
        console.error(error);
        toast.error('Errore ricezione macchine.', error);
        throw error;
    }
}