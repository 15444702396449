import { Auth } from "aws-amplify";


export async function getPersonalUserInfo() {
    try {
        const user = await Auth.currentUserInfo();


        const { username, attributes } = user;
        const { email, name, family_name: surname,
            'custom:configUser': configUser
        } = attributes;

        const configUserJson = JSON.parse(configUser);



        return {
            username,
            email,
            name,
            surname,
            configUser: configUserJson,
        };
    } catch (error) {
        console.error('Errore durante il recupero delle informazioni dell\'utente:', error);
        Auth.signOut();  /***----------------------AGGIUNGERE DELETE COOKIES */
        throw error;
    }
}


export async function getUserConfigSys() {
    try {
      const user = await Auth.currentUserInfo();
      const { attributes } = user;
      const { 'custom:configSys': configSys } = attributes;
      const configSysJson = JSON.parse(configSys);
      return configSysJson.FMM
  
  
    } catch (error) {
  
    }
  }