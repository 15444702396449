import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';

import {Amplify, PubSub} from 'aws-amplify';
import awsExports from './aws-exports';


import './components/translation/i18n';


Amplify.configure(awsExports);
//PubSub.configure(awsExports);
/*Hub.listen('pubsub', (data) => {
  const { payload } = data;
  console.log(payload)
});*/

const root = ReactDOM.createRoot(document.getElementById('root'));
/*root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);*/
root.render(<App />);
