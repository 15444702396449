import { Flex, Text, useTheme } from "@aws-amplify/ui-react";
//import config from'../../configParams.json';
export function Footer() {
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="center" padding={tokens.space.medium}>
      <Text>&copy;</Text>{/*config.FooterLogin.textFooter*/}
    </Flex>
  );
}
