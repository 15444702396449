import React, { Component } from 'react';
import { Button, Card, Form, Table } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
class ConfigSys extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: {},
            editedConfigSys: JSON.parse(JSON.stringify(props.configSys)), // Deep copy of configSys
        };
    }

  
    toggleEdit = (factoryIndex) => {
        this.setState(prevState => {
            const isEditing = {
                ...prevState.isEditing,
                [factoryIndex]: !prevState.isEditing[factoryIndex], // Commuta lo stato di modifica per la card specifica
            };

            if (!isEditing[factoryIndex]) {
                // Quando usciamo dalla modalità di modifica, salviamo le modifiche
                let newUser = this.props.user;
                newUser.Attributes.find(attr => attr.Name === 'custom:configSys').Value = JSON.stringify(prevState.editedConfigSys);
                this.props.sendData(newUser);
            }

            return { isEditing };
        });
    };

    cancelEdit = (factoryIndex) => {
        // Reimposta la configurazione specifica quando l'editing viene annullato
        this.setState(prevState => ({
            isEditing: { ...prevState.isEditing, [factoryIndex]: false },
            editedConfigSys: JSON.parse(JSON.stringify(this.props.configSys)),
        }));
    };

    handleChange = (factoryIndex, key, value) => {
        this.setState(prevState => {
            const newState = { ...prevState.editedConfigSys };
            const factoryKey = Object.keys(newState.FMM.factories[factoryIndex])[0];
            newState.FMM.factories[factoryIndex][factoryKey][key] = value;
            return { editedConfigSys: newState };
        });
    }

    handleArrayChange = (factoryIndex, key, itemIndex, value) => {
        this.setState(prevState => {
            const newState = { ...prevState.editedConfigSys };
            const factoryKey = Object.keys(newState.FMM.factories[factoryIndex])[0];
            newState.FMM.factories[factoryIndex][factoryKey][key][itemIndex] = value;
            return { editedConfigSys: newState };
        });
    }


    handleChangeForSelect = (factoryIndex, key, value) => {
        this.setState(prevState => {
            const newState = { ...prevState.editedConfigSys };
            const factoryKey = Object.keys(newState.FMM.factories[factoryIndex])[0];
            newState.FMM.factories[factoryIndex][factoryKey][key] = value; // Ora `value` è una stringa e non un array
            return { editedConfigSys: newState };
        });
    }


    handleAddPostazione = (factoryIndex, key) => {
        this.setState(prevState => {
            const newState = JSON.parse(JSON.stringify(prevState.editedConfigSys)); // Deep copy per evitare mutazioni dirette
            const factoryKey = Object.keys(newState.FMM.factories[factoryIndex])[0];
            if (!newState.FMM.factories[factoryIndex][factoryKey][key]) {
                newState.FMM.factories[factoryIndex][factoryKey][key] = []; // Assicurati che l'array esista
            }
            newState.FMM.factories[factoryIndex][factoryKey][key].push(''); // Aggiungi un nuovo elemento vuoto
            return { editedConfigSys: newState };
        });
    }
    handleRemovePostazione = (factoryIndex, key, itemIndex) => {
        this.setState(prevState => {
            const newState = JSON.parse(JSON.stringify(prevState.editedConfigSys)); // Deep copy per evitare mutazioni dirette
            const factoryKey = Object.keys(newState.FMM.factories[factoryIndex])[0];
            newState.FMM.factories[factoryIndex][factoryKey][key].splice(itemIndex, 1); // Rimuovi l'elemento all'indice specificato
            return { editedConfigSys: newState };
        });
    };
    getLevelAuthDescription(value) {
        const descriptions = {
            '-1': this.props.t('Superadmin'),
            '0': this.props.t('Capo Stabilimento'),
            '1': this.props.t('Capo Reparto'),
            '2': this.props.t('Postazione')
        };
        return descriptions[value] || this.props.t('Non specificato');
    }
    


    renderFactoryTables() {
        const { editedConfigSys, isEditing } = this.state;
        const opzioniReparto = ["TaglioPelle", "TaglioAusiliari"];

        if (!editedConfigSys || !editedConfigSys.FMM || !editedConfigSys.FMM.factories) {
            return null;
        }

        return editedConfigSys.FMM.factories.map((factoryObj, index) => {
            const factoryName = Object.keys(factoryObj)[0];
            const factoryDetails = factoryObj[factoryName];
            return (
                <Card key={index} className="mb-3">
                    <Card.Header>
                        <div className='titleCard'>
                            {`Configurazione per ${factoryName}`}
                            {isEditing[index] ?
                                (<>
                                    <Button className='confirmButton' onClick={() => this.toggleEdit(index)} style={{ float: 'right', marginLeft: '10px' }}>
                                        Conferma Modifica
                                    </Button>
                                    <Button className='cancelButton' variant='warning' onClick={() => this.cancelEdit(index)} style={{ float: 'right' }}>
                                        Annulla Modifiche
                                    </Button>
                                </>
                                ) : (
                                    <Button className='modButton' onClick={() => this.toggleEdit(index)} style={{ float: 'right' }}>
                                        Modifica
                                    </Button>
                                )
                            }
                        </div>
                    </Card.Header>
                    <Card.Body style={{ fontSize: 'small' }}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Configurazione</th>
                                    <th>Valore</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Policy MQTT</td>
                                    <td>  {isEditing[index] ? (
                                        <Form.Group>
                                            <Form.Control
                                                className='formNumber'
                                                type="number"
                                                value={factoryDetails.policyMqtt}
                                                onChange={(e) => this.handleChange(index, 'policyMqtt', parseInt(e.target.value))}
                                            />
                                        </Form.Group>
                                    ) : (factoryDetails.policyMqtt)}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Level Auth</td>
                                    <td>

                                        {isEditing[index] ? (
                                            <Form.Group>
                                                {/* <Form.Control
                                                    className='formNumber'
                                                    type="number"
                                                    value={factoryDetails.levelAuth}
                                                    onChange={(e) => this.handleChange(index, 'levelAuth', parseInt(e.target.value))}
                                        />*/}

                                                <Form.Select
                                                    className='formNumber'
                                                    size="sm"
                                                    type="number"
                                                    value={factoryDetails.levelAuth}
                                                    onChange={(e) => this.handleChange(index, 'levelAuth', parseInt(e.target.value))}
                                                >
                                                    <option value={-1}>Superadmin</option>
                                                    <option value={0}>Capo Stabilimento</option>
                                                    <option value={1}>Capo Reparto</option>
                                                    <option value={2}>Postazione</option>
                                                </Form.Select> </Form.Group>
                                        ) : (
                                            this.getLevelAuthDescription(factoryDetails.levelAuth)
                                        )}</td>
                                </tr>
                                {factoryDetails.POSTAZIONE && (
                                    <tr>
                                        <td>Postazione</td>
                                        <td> {isEditing[index] ? (
                                            <Form.Group>
                                                {factoryDetails.POSTAZIONE.map((postazione, postIndex) => (
                                                    <div key={postIndex} style={{ display: 'flex', marginBottom: '1%' }}>

                                                        <Form.Control key={postIndex}
                                                            className='formEditConfig'
                                                            type="text"
                                                            value={postazione}
                                                            onChange={(e) => this.handleArrayChange(index, 'POSTAZIONE', postIndex, e.target.value)}
                                                        />
                                                        <Button className='deleteButton' variant="danger" size='sm' onClick={() => this.handleRemovePostazione(index, 'POSTAZIONE', postIndex)}>Rimuovi</Button>

                                                    </div>
                                                ))}
                                                <Button className='addButton' size='sm' onClick={() => this.handleAddPostazione(index, 'POSTAZIONE')}>Aggiungi Postazione</Button>
                                            </Form.Group>

                                        ) : (
                                            factoryDetails.POSTAZIONE.join(", ")
                                        )}</td>
                                    </tr>
                                )}
                                {factoryDetails.REPARTO && (
                                    <tr>
                                        <td>Reparto</td>
                                        <td>{isEditing[index] ? (


                                            <Form.Select
                                                size="sm"

                                                className='formEditConfig'
                                                value={factoryDetails.REPARTO}
                                                onChange={(e) => this.handleChangeForSelect(index, 'REPARTO', e.target.value)}
                                            >
                                                {opzioniReparto.map((opzione) => (
                                                    <option key={opzione} value={opzione}>{opzione}</option>
                                                ))}
                                            </Form.Select>


                                        ) : (
                                            factoryDetails.REPARTO
                                        )}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card >
            );
        });
    }

    render() {
        return (
            <div>
                {this.renderFactoryTables()}
            </div>
        );
    }
}

export default withTranslation()(ConfigSys);
