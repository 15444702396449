import AWS from 'aws-sdk';
import awsConfig from '../../aws-exports';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';

const regione = awsConfig.aws_cognito_region;
const userPoolId = awsConfig.aws_user_pools_id;
const IdentityPoolId = awsConfig.aws_cognito_identity_pool_id;



export async function GetListUsers() {
    const session = await Auth.currentSession();
    const userJwtToken = await session.getIdToken().jwtToken;

    const loginsKey = `cognito-idp.${regione}.amazonaws.com/${userPoolId}`;

    AWS.config.update({
        region: regione,
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: IdentityPoolId,
            Logins: {
                [loginsKey]: userJwtToken
            }
        })
    });

    const provider = new AWS.CognitoIdentityServiceProvider();
    const params = {
        UserPoolId: userPoolId,
        // Aggiungi altri parametri se necessario
    };

    return new Promise((resolve, reject) => {
        provider.listUsers(params, (err, data) => {
            if (err) {
                reject(err);
            } else {
                resolve(data.Users);
            }
        });
    });
}

export async function GetUserAttributes(Username) {
    let User = null;
    const Users = await GetListUsers();
    Users.map((us, i) => {
        if (us.Username === Username) {
            User = us;
        }
    })

    return User;
}

export async function UpdateUser(user, AttributesToUpdate) {
    const session = await Auth.currentSession();
    const userJwtToken = session.getIdToken().getJwtToken();
    const loginsKey = `cognito-idp.${regione}.amazonaws.com/${userPoolId}`;
    /*AWS.config.update({
        region: regione,
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: IdentityPoolId,
        }),
    });*/

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
        Logins: {
            [loginsKey]: userJwtToken,
        },
    });
    const provider = new AWS.CognitoIdentityServiceProvider();
    const params = {
        UserPoolId: userPoolId,
        Username: user.Username,
        // Gli attributi da aggiornare sono passati come array di oggetti con chiavi "Name" e "Value"
        UserAttributes: AttributesToUpdate,
    };

    return new Promise((resolve, reject) => {
        provider.adminUpdateUserAttributes(params, (err, data) => {
            if (err) {
                reject(err);
                toast.error('Errore nell invio dei dati.', err);
            } else {
                resolve(data);
                toast.success('Dati inviati con successo!');
            }
        });
    });
}


export async function UpdateUserAttributes(user) {
    const username = user.Username;
    console.log(user);
    const configUser = user.Attributes.find(attr => attr.Name === 'custom:configUser').Value;
    const configSys = user.Attributes.find(attr => attr.Name === 'custom:configSys').Value;
    const name = user.Attributes.find(attr => attr.Name === 'name').Value;
    const family_name = user.Attributes.find(attr => attr.Name === 'family_name').Value;
    const email = user.Attributes.find(attr => attr.Name === 'email').Value;

    const attributesToUpdate = [
        {
            Name: "custom:configUser",
            Value: configUser
        },
        {
            Name: "custom:configSys",
            Value: configSys
        },
        {
            Name: "email",
            Value: email
        }, {
            Name: "name",
            Value: name
        }, {
            Name: "family_name",
            Value: family_name
        },

    ];

    console.log('attributesToUpdate', attributesToUpdate);
    await UpdateUser(user, attributesToUpdate);
    /*await UpdateUser(username, attributesToUpdate)
        .then(data => console.log("Attributi aggiornati con successo", data))
        .catch(err => console.error("Errore nell'aggiornamento degli attributi", err));*/
}

export async function CreateUser(userData) {
    const session = await Auth.currentSession();
    const userJwtToken = session.getIdToken().getJwtToken();
    const loginsKey = `cognito-idp.${regione}.amazonaws.com/${userPoolId}`;

    AWS.config.update({
        region: regione,
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: IdentityPoolId,
            Logins: {
                [loginsKey]: userJwtToken
            }
        })
    });

    const provider = new AWS.CognitoIdentityServiceProvider();
    const params = {
        UserPoolId: userPoolId,
        Username: userData.username,
        UserAttributes: [
            { Name: 'name', Value: userData.name },
            { Name: 'family_name', Value: userData.family_name },
            { Name: 'email', Value: userData.email },
            { Name: 'email_verified', Value: 'true' }, // Se vuoi forzare la verifica
            // Aggiungi qui altri attributi custom se necessario
        ],
        // TemporaryPassword: 'UnaPasswordTemporanea', // Se vuoi impostare una password temporanea
        MessageAction: 'SUPPRESS', // Se NON vuoi che Cognito invii una mail di invito all'utente
    };
    if (userData.password && userData.password.trim() !== '') {
        params.TemporaryPassword = userData.password;
    }
    if (userData.configUser) {
        params.UserAttributes.push({ Name: 'custom:configUser', Value: JSON.stringify(userData.configUser) });
    }
    if (userData.configSys) {
        params.UserAttributes.push({ Name: 'custom:configSys', Value: JSON.stringify(userData.configSys) });
    }
    // E così via per gli altri attributi custom

    return new Promise((resolve, reject) => {
        provider.adminCreateUser(params, (err, data) => {
            if (err) {
                console.error(err);
                reject(err);
                toast.error('Errore nella creazione del nuovo utente.', err);
            } else {
                console.log('Utente creato:', data);
                resolve(data);
                toast.success('Utente creato con successo!');
            }
        });
    });
}
export async function SetPermanentPassword(username, password) {
    const session = await Auth.currentSession();
    const userJwtToken = session.getIdToken().getJwtToken();
    const loginsKey = `cognito-idp.${regione}.amazonaws.com/${userPoolId}`;

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
        Logins: {
            [loginsKey]: userJwtToken,
        },
    });

    const provider = new AWS.CognitoIdentityServiceProvider();
    const params = {
        Password: password, 
        UserPoolId: userPoolId,
        Username: username,
        Permanent: true // Imposta questo parametro su true per bypassare lo stato "FORCE_CHANGE_PASSWORD"
    };

    return new Promise((resolve, reject) => {
        provider.adminSetUserPassword(params, (err, data) => {
            if (err) {
                reject(err);
               
            } else {
                resolve(data);
       
            }
        });
    });
}


export async function DeleteUser(username) {
    const session = await Auth.currentSession();
    const userJwtToken = session.getIdToken().getJwtToken();
    const loginsKey = `cognito-idp.${regione}.amazonaws.com/${userPoolId}`;

    AWS.config.update({
        region: regione,
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: IdentityPoolId,
            Logins: {
                [loginsKey]: userJwtToken
            }
        })
    });

    const provider = new AWS.CognitoIdentityServiceProvider();
    const params = {
        UserPoolId: userPoolId,
        Username: username
    };

    return new Promise((resolve, reject) => {
        provider.adminDeleteUser(params, (err, data) => {
            if (err) {
                console.error('Errore nell\'eliminazione dell\'utente:', err);
                reject(err);
                toast.error('Errore nell\'eliminazione dell\'utente.', err);
            } else {
                console.log('Utente eliminato con successo:', data);
                resolve(data);
                toast.success('Utente eliminato con successo!');
            }
        });
    });
}
