import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AWS from 'aws-sdk';
import awsConfig from '../../../aws-exports';
import { Auth } from 'aws-amplify';
import { Button, Card, Col, NavLink, Row, Table } from 'react-bootstrap';
import Loading from '../../Loading';
import { use } from 'i18next';
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { GetListUsers } from '../../../components/Functions/GetUsersInfo';
import './Users.css';


const regione = awsConfig.aws_cognito_region; // ad esempio, 'eu-west-1'
const userPoolId = awsConfig.aws_user_pools_id;
const IdentityPoolId = awsConfig.aws_cognito_identity_pool_id;


class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: null, // Per memorizzare l'elenco degli utenti
        };

        this.provider = new AWS.CognitoIdentityServiceProvider();
    }

    async componentDidMount() {
        const users = await GetListUsers();
        this.setState({ users });
    }


    NavToNewUserForm() {
        //return <Link to="/Settings/Users/newUser"/>

        return <Link to="/Users/newUser" />;

    }
    render() {
        const { users } = this.state;

        if (users == null) {
            return <Loading />;
        }
        return (
            <Card  className='cardModifiche'>
                <Card.Header style={{display:'flex'}}>
                    <div className='titleCard'>Utenti</div>
                    <Link to="/Users/newUser" style={{position:'absolute', right:'1%'}} ><Button className='confirmButton' style={{ float: 'right' }} onClick={() => this.NavToNewUserForm()}>Aggiungi Nuovo Utente  </Button></Link>
                </Card.Header>
                <Card.Body>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Nome</th>
                                <th>Cognome</th>
                                <th>Email</th>

                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user, index) => (
                                <tr key={index}>
                                    <td>
                                        <Link to={`/Users/${user.Username}`}>{user.Username}</Link>
                                    </td>

                                    <td>{
                                        user.Attributes.find(attr => attr.Name === 'name')?.Value || 'N/A'
                                    }</td>
                                    <td>{
                                        user.Attributes.find(attr => attr.Name === 'family_name')?.Value || 'N/A'
                                    }</td>
                                    <td>{
                                        user.Attributes.find(attr => attr.Name === 'email')?.Value || 'N/A'
                                    }</td>

                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    }
}
export default withTranslation()(Users);



export function UsersWrapper() {
    const params = useParams(); // Ottieni i parametri dell'URL, ad es. { username: "nome_utente" }
    let navigate = useNavigate();
    return <Users params={params} navigate={navigate} />;
}
